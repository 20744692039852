<template>
  <div
    class="d-flex flex-column flex-lg-row flex-grow-1 bloc-group pl-1 pr-1 pl-md-3 pr-md-3 pl-lg-5 pr-lg-5 rounded"
    :class="bgClass"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ContainerPage",
  props: {},
  computed: {
    bgClass: function () {
      if (this.$store.getters["colors/darkMode"]) {
        return "text-white";
      }
      return "text-black";
    },
    bgColor: function () {
      return this.$store.getters["colors/colors"].color2;
    },
  },
};
</script>
<style lang="css">
@media (min-width: 992px) {
  .bloc-group > div:first-child {
    margin-right: 1rem;
  }
  .bloc-group > div:only-child {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .bloc-group > form:only-child {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
</style>
