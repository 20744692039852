<template>
  <ContainerPage>
    <router-view></router-view>
  </ContainerPage>
</template>
<script>
import ContainerPage from "../components/containers/ContainerPage.vue";

export default {
  name: "settings",
  components: { ContainerPage },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style></style>
